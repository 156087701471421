import React from 'react'
import { widget, wrapper, errorStyle } from './styles.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { postEmailSubscribe } from '../../api/postApi'
import { useNotify } from '../../hooks/useNotify'
import cn from 'classnames'

const FollowWidget = ({ className }) => {
  const { notify } = useNotify()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Field is required'),
    }),

    onSubmit: (values) => {
      postEmail(values)
    },
  })

  const { values, errors, touched } = formik

  const postEmail = async (data) => {
    try {
      await postEmailSubscribe(data)

      const message = 'You have successfully subscribed to the email newsletter'
      notify('Success', message)
      formik.resetForm()
    } catch (error) {
      notify.errorsList(error.errors)
    }
  }

  const handleChange = (event) => {
    if (!formik.touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    formik.handleChange(event)
  }
  return (
    <div className={cn(widget, className)}>
      <form onSubmit={formik.handleSubmit}>
        <div className={wrapper}>
          <div className="form-group">
            <Input
              name="email"
              value={values.email}
              placeholder="Enter your Email"
              onChange={handleChange}
              isInvalid={errors.email && touched.email}
              theme={'widget'}
            />

            {errors.email && touched.email && (
              <div style={{ color: '#ffffff' }} className={cn('input-error-message', errorStyle)}>
                {errors.email}
              </div>
            )}
          </div>

          <Button
            style={{
              backgroundColor: '#43927C',
              borderRadius: '8px',
              alignSelf: 'flex-start',
            }}
            type="submit"
            size="small"
          >
            Follow
          </Button>
        </div>
      </form>
    </div>
  )
}

export default FollowWidget
