import styles from '../CompanyDataPage.module.scss'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { initialValues } from './config'
import SelectSearch from '../../../UI/Select/SelectSearch'
import cn from 'classnames'
import FileUploader from '../../../UI/FileUploader/FileUploader'
import { notify } from '../../../helpers/notify'
import { getGooglePlacesAddress, getGooglePlacesCities } from '../../../api/getApi'
import { getPrettierCities } from '../../../components/Company/VacancyManagerForm/utils'
import Autocomplete from '../../../UI/Select/Autocomplete'
import { isValidFile } from '../../../helpers/helpers'
import { confirm } from '../../../UI/Confirm/Confirm'
import { validationSchema } from './config'
import Contacts from './Contacts'
import Address from './Address'
import { putCompanyAdditionalContact, putCompanyAdditionalInfo } from '../../../api/putApi'
import { postCompanyAdditionalContact, postCompanyAdditionalFiles } from '../../../api/postApi'
import { deleteAdditionalContact, deleteAdditionalFile } from '../../../api/deleteApi'
import Spinner from '../../../UI/Spinner/Spinner'

const AdditionalForm = ({
  companyData,
  documentData,
  isLoading,
  setLoading,
  filters,
  container,
  isEditMode,
  setIsEditMode,
}) => {
  const extensions = ['.pdf', '.jpg', '.jpeg', '.png']

  const [cities, setCities] = useState([])
  const [address, setAddress] = useState([])
  const [validPhoneContact, setValidPhoneContact] = useState(null)
  const [validEmailContact, setValidEmailContact] = useState(null)

  useEffect(() => {
    const diff = Date.parse(documentData?.updated_at) - Date.parse(documentData?.created_at)

    setIsEditMode(diff < 2000)

    // eslint-disable-next-line
  }, [documentData])

  // const webSiteLinkCreator = async (link: string): Promise<string> => {
  //   if (/^https?:\/\//.test(link)) {
  //     return link
  //   } else {
  //     const httpsURL = `https://${link}`
  //
  //     try {
  //       const response = await fetch(httpsURL)
  //       if (response.ok) {
  //         return httpsURL
  //       }
  //     } catch (error) {}
  //
  //     const httpURL = `http://${link}`
  //     try {
  //       const response = await fetch(httpURL)
  //       if (response.ok) {
  //         return httpURL
  //       }
  //     } catch (error) {}
  //     return ''
  //   }
  // }

  // const addDataToForm = async () => {
  //   if (!documentData) return
  //   if (documentData?.website) {
  //     const link = await webSiteLinkCreator(documentData.f)
  //     return {
  //       ...documentData,
  //       website: link,
  //     }
  //   }
  // }

  const formik = useFormik({
    initialValues: documentData ? { ...documentData } : { ...initialValues },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (document) => {
      setLoading(true)

      const postDocumentData = {
        country: document?.country?.id || null,
        address: document.address,
        city: document.city,
        zip: document.zip,
        website: document.website,
        information: document.information,
        office: document.office,
      }
      const postDocument = async (document) => {
        try {
          const uploadAdditionalData = async (data, uploadFunction, deleteFunction, updateFunction) => {
            if (data === 'documents') {
              const uploadData = values[data].filter((item) => !item.id)
              for (const item of uploadData) {
                await uploadFunction(item)
              }
              for (const itemData of documentData[data]) {
                if (!values[data].find((item) => item.id === itemData.id)) {
                  await deleteFunction(itemData.id)
                }
              }
              return
            }

            const documentDataItems = documentData[data].results
            const valuesItemsWithId = values[data].results.filter((item) => item.id)
            const newData = values[data].results.filter((item) => !item.id)

            for (const itemData of documentDataItems) {
              const actualContact = valuesItemsWithId.find((item) => item.id === itemData.id)
              actualContact
                ? itemData !== actualContact &&
                  (await updateFunction(
                    {
                      type: actualContact.type.id,
                      contact: actualContact.contact,
                      comment: actualContact.comment ? actualContact.comment : null,
                    },
                    actualContact.id
                  ))
                : await deleteFunction(itemData.id)
            }

            for (const item of newData) {
              await uploadFunction(item)
            }
          }

          await uploadAdditionalData('documents', postCompanyAdditionalFiles, deleteAdditionalFile)
          await uploadAdditionalData(
            'phone_contacts',
            postCompanyAdditionalContact,
            deleteAdditionalContact,
            putCompanyAdditionalContact
          )
          await uploadAdditionalData(
            'email_contacts',
            postCompanyAdditionalContact,
            deleteAdditionalContact,
            putCompanyAdditionalContact
          )

          const response = await putCompanyAdditionalInfo(document, values.id)

          if (response.errors) {
            notify.errorsList(response.errors)

            return
          }

          notify('Success', 'Your education was successfully added.')

          formik.resetForm()
          setIsEditMode(false)
        } catch (error) {
          notify.errorsList(error.errors)
          setIsEditMode(true)
        } finally {
          setLoading(false)
        }
      }

      postDocument(postDocumentData)
    },
  })
  const { values, errors, touched, handleSubmit } = formik

  const getCities = useCallback(
    async (value) => {
      setCities([])

      try {
        const iso = filters.country.find((country) => country?.id === values.country?.id)?.iso2

        const response = await getGooglePlacesCities(value, iso)

        setCities(getPrettierCities(response))
      } catch (error) {
        notify.errorsList(error.errors)
      }
    },

    // eslint-disable-next-line
    [filters.country, values.country?.id]
  )
  const getAddress = useCallback(
    async (address) => {
      try {
        const id = values.city.id

        if (!id) return

        const response = await getGooglePlacesAddress(address, id)

        setAddress(response)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    },

    // eslint-disable-next-line
    [values.city?.id]
  )

  const changeAutocompleteItems = useCallback(
    (name, value) => {
      if (name === 'city' && value.length > 2) getCities(value)
      if (name === 'address' && value.length > 2) {
        getAddress(value)
      }
    },
    [getCities, getAddress]
  )
  const handleAutocompleteChange = useCallback(
    (name, { target }) => {
      const value = { id: null, name: target.value }

      handleValueChange({
        target: { name, value },
      })

      changeAutocompleteItems(name, target.value)
    },

    [changeAutocompleteItems] // eslint-disable-line
  )

  const handleAutocompleteFocus = useCallback(
    (name, { target }) => {
      changeAutocompleteItems(name, target.value)
    },
    [changeAutocompleteItems]
  )

  const handleValueChange = (e) => {
    const { name } = e.target

    if (!touched[name]) formik.setFieldTouched(name, true)

    formik.handleChange(e)
  }

  const handleSelectChange = (name, value) => {
    const clearValue = { id: value.id, name: value.name }
    if (name === 'country') {
      formik.setValues({
        ...values,
        city: { id: null, name: '' },
        address: { id: null, name: '' },
        [name]: clearValue,
      })
    }
    if (name === 'city') {
      formik.setValues({
        ...values,
        address: { id: null, name: '' },
        [name]: clearValue,
      })
    }

    handleValueChange({ target: { name, value: clearValue } })
  }

  const handleChangeFormikValue = (type, data, index) => {
    if (type === 'phone')
      formik.handleChange({
        target: { value: data.replace(/ /g, ''), name: `phone_contacts.results[${index}].contact` },
      })
    if (type === 'phone_comment')
      formik.handleChange({ target: { value: data, name: `phone_contacts.results[${index}].comment` } })
    if (type === 'email')
      formik.handleChange({ target: { value: data, name: `email_contacts.results[${index}].contact` } })
    if (type === 'email_comment')
      formik.handleChange({ target: { value: data, name: `email_contacts.results[${index}].comment` } })

    formik.handleChange({ target: { value: data, name: type } })
  }

  const handleInputFileChange = (event) => {
    const files = event.target.files
    for (let i = 0; i < event.target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (values.documents.length < 5) {
        if (isValidFile(file, extensions) !== -1) {
          handleChangeFormikValue('documents', [...values.documents, file])
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 5 files`)
      }
    }
  }
  const handleInputFileDelete = (name) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      const files = values.documents.filter((item) => item.name !== name)
      handleChangeFormikValue('documents', files)
    })
  }
  function stringChange(str) {
    if (/\d/.test(str)) {
      let result = ''
      let digitsRemoved = false
      for (let char of str) {
        if (!digitsRemoved && isNaN(parseInt(char))) {
          result += char
        } else {
          digitsRemoved = true
        }
      }
      return result.slice(0, -1)
    } else {
      return str
    }
  }

  const handleInputChange = (event, index) => {
    const { name, value } = event.target
    handleChangeFormikValue(stringChange(name), value, index)
  }

  return (
    <>
      {isLoading ? (
        <div className={styles.spinnerBlock}>
          <Spinner withoutPosition />
        </div>
      ) : (
        <form className={styles.company_additional_form} onSubmit={handleSubmit}>
          <div className="profile-form-main">
            <div className="profile-form-fields">
              <div className="profile-form-column">
                <div className={styles.item}>
                  <div
                    className={cn(styles.additional_info, {
                      [styles.additional_info_unboarder]: values.documents?.length,
                    })}
                  >
                    <FileUploader
                      files={values.documents}
                      onChange={handleInputFileChange}
                      onDelete={handleInputFileDelete}
                      name={'Add documents'}
                      text={'Add documents'}
                      title={'Additional info'}
                      disabled={!isEditMode || !companyData.is_active}
                    />
                  </div>
                </div>
                <div className={styles.item}>
                  <Input
                    className={styles.company_data_input}
                    name="information"
                    label="Company Information"
                    value={values.information}
                    onChange={handleInputChange}
                    disabled={!isEditMode || !companyData.is_active}
                    isInvalid={!!errors.information}
                    errorMessage={errors.information}
                    textarea
                  />
                </div>
                <div className={styles.item}>
                  <div className={styles.item_body_main}>
                    <div className={styles.selectBlock}>
                      <SelectSearch
                        label="Country"
                        name="country"
                        items={filters?.country}
                        id={values.country?.id}
                        onClick={handleSelectChange}
                        selectedItem={values.country}
                        disabled={!isEditMode || !companyData.is_active}
                        // isInvalid={Boolean(errors.country?.name && touched.country)}
                        // errorMessage={errors.country?.name}
                        // required
                      />
                    </div>
                    <div className={styles.selectBlock}>
                      <Autocomplete
                        label="City"
                        name="city"
                        placeholder=""
                        getTitle="name"
                        value={values.city?.name}
                        items={cities}
                        onChange={handleAutocompleteChange}
                        onFocus={handleAutocompleteFocus}
                        onClick={handleSelectChange}
                        // isInvalid={Boolean(errors.city?.name && touched.city)}
                        // errorMessage={errors.city?.name}
                        disabled={!values.country?.id || !isEditMode || !companyData.is_active}
                        container={container}
                        // required={values.country?.id}
                      />
                    </div>
                  </div>
                </div>
                <Address
                  handleAutocompleteFocus={handleAutocompleteFocus}
                  handleAutocompleteChange={handleAutocompleteChange}
                  handleAutocompleteClick={handleSelectChange}
                  formik={formik}
                  address={address}
                  handleInputChange={handleInputChange}
                  isInputsDisabled={!isEditMode || !companyData.is_active}
                />
                <div className={styles.item}>
                  {values.website && !isEditMode && companyData.is_active ? (
                    <div className={styles.linkBlock}>
                      <span>Website</span>
                      <div className={styles.linkBlock_content}>
                        <a href={values.website} target="_blank" rel="noopener noreferrer">
                          {values.website}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <Input
                      className={styles.company_data_input}
                      name="website"
                      label="Website"
                      value={values.website}
                      onChange={handleInputChange}
                      isInvalid={!!errors.website}
                      errorMessage={errors.website}
                      disabled={!isEditMode || !companyData.is_active}
                    />
                  )}
                </div>
                <br />
                <Contacts
                  documentData={documentData}
                  values={values}
                  handleInputChange={handleInputChange}
                  isInputsDisabled={!isEditMode || !companyData.is_active}
                  formik={formik}
                  isEditMode={isEditMode}
                  setValidPhoneContact={setValidPhoneContact}
                  setValidEmailContact={setValidEmailContact}
                />
                <div className={styles.actionBlock}>
                  <Button
                    theme={'transparent'}
                    onClick={(e) => {
                      e.preventDefault()
                      setIsEditMode(!isEditMode)
                      formik.resetForm()
                    }}
                    type="edit"
                    className={styles.addiction_form_save_btn}
                    disabled={!companyData.is_active}
                  >
                    {isEditMode ? 'Close' : 'Edit information'}
                  </Button>
                  <Button
                    type="submit"
                    className={styles.addiction_form_save_btn}
                    disabled={
                      !isEditMode ||
                      Object.keys(errors)[0] ||
                      values.phone_contacts.results.find((item) => item === validPhoneContact) ||
                      values.email_contacts.results.find((item) => item === validEmailContact) ||
                      !companyData.is_active
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}
export default AdditionalForm
