import { setToken } from './user.action'
import { request } from '../../api/auth.service'
import { API_BASE_V2, CLIENT_ID, CLIENT_SECRET, METHOD } from '../../api/constans'

export const getToken = (data, notify) => {
  return (dispatch) => {
    return dispatch(userPostFetch(`token/`, data, notify))
  }
}

const userPostFetch = (url, data, notify) => {
  return async (dispatch) => {
    dispatch(setIsButtonState(false))

    try {
      const response = await request(url, {
        api: API_BASE_V2,
        method: METHOD.POST,
        headers: new Headers(),
        withoutToken: true,
        body: JSON.stringify({
          ...data,
          grant_type: 'password',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          scope: 'company',
        }),
      })

      dispatch(setToken(response))
      dispatch(setIsButtonState(true))
    } catch (error) {
      dispatch(setIsButtonState(true))
      dispatch(throwError(error))

      notify.errorsList(error.errors)
    }
  }
}

const throwError = (obj) => ({
  type: 'LOGIN_FAIL',
  payload: obj,
})

export const userLogout = () => {
  localStorage.clear()

  return {
    type: 'USER_LOGOUT',
  }
}

export const setIsButtonState = (payload) => {
  return {
    type: 'SET_BUTTON_STATE',
    payload,
  }
}
