import React from 'react'
import FollowWidget from '../FollowWidget/FollowWidget'
import Logo from '../../UI/Logo/Logo'
import styles from './Footer.module.scss'
import { ReactComponent as Arrow } from '../../assets/icons/arrow-right-bold.svg'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-footer.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedIn-footer.svg'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram-footer.svg'
import { ReactComponent as TikTok } from '../../assets/icons/tiktok-footer.svg'
import { ReactComponent as Telegram } from '../../assets/icons/telegram-footer.svg'
import { ReactComponent as YouTube } from '../../assets/icons/youtube.svg'
import { getCurrentDomain } from '../../helpers/apiHelper'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.footer_container}>
          <Logo theme="white" className={styles.footer_logo} />
          <div className={styles.footer_follow_widget}>
            <FollowWidget />
          </div>
          <div className={styles.footer_groups}>
            <div className={styles.footer_groups_contact_us}>
              <div className={styles.footer_groups_contact_us_title}>Contact Us</div>
              <div className={styles.footer_groups_contact_us_content}>
                <a href="mailto:help@nextship.net">help@nextship.net</a>
              </div>
            </div>
            <div className={styles.footer_groups_links}>
              <a href="https://www.facebook.com/nextship.page" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://www.linkedin.com/company/nextship" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon />
              </a>
              <a href="https://www.instagram.com/next.ship/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
              <a href="https://www.tiktok.com/@nextship.app" target="_blank" rel="noopener noreferrer">
                <TikTok />
              </a>
              <a href="https://t.me/nextshipapp" target="_blank" rel="noopener noreferrer">
                <Telegram />
              </a>
              <a
                href="https://www.youtube.com/channel/UCWSBAkCrcK5hy3y25rYfpKA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTube />
              </a>
            </div>
          </div>
          <div className={styles.footer_for_shipowner}>
            <div className={styles.footer_for_shipowner_link}>
              <a href={`${getCurrentDomain()}nextship.net/`}>
                For seafarers <Arrow />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
